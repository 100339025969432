import React, {useEffect, useState} from 'react';
import {notification, Row, Spin} from 'antd';
import Spinner from "../../../../components/Spinner";
import {brandsApi} from "../../../../api/brands.api";
import BrandForm from "../Form";

export default function BrandCreateForm () {
    const [brandData, setBrandData] = useState(null);
    const [blockedSubmit, setBlockedSubmit] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            setBrandData({
                "title": "",
                "slug": "",
                "active": 1,
                "seoPreviewImage": "https://cdn.rb-design.ru/files/posters/---.jpg",
                "seoDescription": ""
            });
            setLoading(false);
        }
    }, [])

    const onSendFormToApi = (value) => {
        const newData = {...brandData, ...value};
        setLoading(true);
        setBrandData(newData);

        brandsApi.createBrand(newData)
            .then((data) => {
                notification.success({message: data.message.toString()});
                setValidationErrors([]);
                setBrandData(data.data);
                setBlockedSubmit(true);
                setLoading(false);
            })
            .catch((data) => {
                if (data.status === 400) {
                    setValidationErrors(data.data.errors);
                } else {
                    notification.error({message: data.toString()});
                }
                setLoading(false);
            })
    };

    if (loading && brandData === null) {
        return (<Spinner loading={loading}/>);
    }

    return (
        <Row justify="center" >
            <Spin spinning={loading}>
                <BrandForm
                    brandData={brandData}
                    onSendFormToApi={onSendFormToApi}
                    validationErrors={validationErrors}
                    loading={loading}
                    isBlockedSubmit={blockedSubmit}
                />
            </Spin>
        </Row>
    );
};