import React, {useEffect, useState} from 'react';
import {notification, Spin} from 'antd';
import Spinner from "../../../../../components/Spinner";
import {CONTENT_BLOCK_TYPE_HTML_V1} from "../ContentBlocks";
import PostForm from "../Form";
import {blogPostApi} from "../../../../../api/blog/posts.api";

export default function BlogPostEditForm ({postId}) {
    const [postData, setPostData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [listContentBlocks, setListContentBlocks] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);

    function generateNewId () {
        return (Math.random() + 1).toString(36).substring(7);
    }

    useEffect(() => {
        blogPostApi.getPost(postId).then((data) => {
            setPostData(data);
            setListContentBlocks(
                data.contentBlocks.map((item) => {
                    return {...item, id: generateNewId()}
                })
            )
        });
    }, []);

    useEffect(() => {
        if (loading && postData !== null) {
            setLoading(false);
        }
    }, [postData])

    const onSendFormToApi = (formData) => {
        setLoading(true);

        const formContentBlocks = listContentBlocks.map((contentBlock) => {
            const nameKey = `content_blocks[${contentBlock.id}]`;
            const valueObject = (contentBlock.type === CONTENT_BLOCK_TYPE_HTML_V1)
                ? {"html": formData[nameKey]}
                : {"json": formData[nameKey]};
            return {
                ...contentBlock,
                ...valueObject
            };
        });

        const newFormData = {
            id: postId,
            titleList: formData.titleList,
            titleDetail: formData.titleDetail,
            slug: formData.slug,
            url: postData.url,
            contentBlocks: formContentBlocks,
            date: formData.date,
            previewType: formData.previewType,
            previewPath: formData.previewPath,
            previewPathPoster: formData.previewPathPoster,
            previewPathVideo: formData.previewPathVideo,
            smallDesc: formData.smallDesc,
            active: formData.active ? 1 : 0,
            seoPreviewImage: formData.seoPreviewImage,
        };

        blogPostApi.updatePost(postId, newFormData)
            .then((data) => {
                notification.success({message: data.message});
                setValidationErrors([]);
                setPostData(data.data);
                setLoading(false);
            })
            .catch((data) => {
                if (data.status === 400) {
                    setValidationErrors(data.data.errors);
                } else {
                    notification.error({message: data.toString()});
                }
                setLoading(false);
            })
    };

    if ((postData === null) && loading) {
        return (<Spinner loading={loading}/>);
    }

    const onDeletePost = (id) => {
        return (e) => {
            e.preventDefault();
            setLoading(true);
            blogPostApi.deletePost(id)
                .then((data) => {
                    notification.success({message: data.message.toString()});
                    setTimeout(() => {
                        window.location.href = "/blog/posts";
                    }, 3000);
                })
                .catch((data) => {
                    notification.error({message: data.toString()});
                    setLoading(false);
                    setTimeout(() => {
                        window.location.href = "/blog/posts";
                    }, 3000);
                });
        }
    }
    
    return (
        <Spin spinning={loading} tip="Обновление...">
            <PostForm
                postData={postData}
                loading={loading}
                listContentBlocks={listContentBlocks}
                setListContentBlocks={setListContentBlocks}
                validationErrors={validationErrors}
                onSendFormToApi={onSendFormToApi}
                isEdit={true}
                onDelete={onDeletePost}
            />
        </Spin>
    );
};