import React, {useEffect, useState} from 'react';
import { notification, Row} from 'antd';
import Spinner from "../../../../components/Spinner";
import {brandsApi} from "../../../../api/brands.api";
import BrandForm from "../Form";

export default function BrandEditForm ({brandId}) {
    const [brandData, setBrandData] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        brandsApi.getBrand(brandId)
            .then((data) => setBrandData(data))
            .catch((data) => {
                notification.error({message: data.toString()});
                setTimeout(() => {
                    window.location.href = "/brands";
                }, 2000);
            });
    }, []);

    useEffect(() => {
        if (loading && brandData !== null) {
            setLoading(false);
        }
    }, [brandData])

    const onSendFormToApi = (value) => {
        const newData = {...brandData, ...value};

        setLoading(true);
        brandsApi.updateBrand(brandId, newData)
            .then((data) => {
                notification.success({message: data.message.toString()});
                setValidationErrors([]);
                setBrandData(data.data);
                setLoading(false);
            })
            .catch((data) => {
                if (data.status === 400) {
                    setValidationErrors(data.data.errors);
                } else {
                    notification.error({message: data.toString()});
                }
                setLoading(false);
            })
    };

    const onDeleteBrand = (id) => {
        return (e) => {
            e.preventDefault();
            setLoading(true);
            brandsApi.deleteBrand(id)
                .then((data) => {
                    notification.success({message: data.message.toString()});
                    setTimeout(() => {
                        window.location.href = "/brands";
                    }, 3000);
                })
                .catch((data) => {
                    notification.error({message: data.toString()});
                    setLoading(false);
                    setTimeout(() => {
                        window.location.href = "/brands";
                    }, 3000);
                });
        }
    }

    if (loading && brandData === null) {
        return (<Spinner loading={loading}/>);
    }

    return (
        <Row justify="center" >
            <BrandForm
                brandData={brandData}
                onSendFormToApi={onSendFormToApi}
                validationErrors={validationErrors}
                loading={loading}
                isEdit={true}
                onDelete={onDeleteBrand}
            />
        </Row>
    );
};