import {Button, Col, Form, Input, notification, Row, Spin} from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {authApi} from "../api/auth.api";
import {useAuthContext} from "../contexts/auth.context";

const HomePage = () => {
    const [loading, setLoading] = useState(true);
    const { handleLogin, setUserName } = useAuthContext();

    useEffect(() => {
        authApi.getUser()
            .then((userName) => {
                setUserName(userName);
                window.location.href = "/works";
            })
            .catch((message) => {
                if (!(message instanceof TypeError)) {
                    notification.error({message: message});
                } else {
                    notification.error({message: 'Нет доступа'});
                }
            })
            .finally((res) => setLoading(false));
    }, []);

    const onFinish = (formData) => {
        setLoading(true);

        authApi
            .login(formData.email, formData.password)
            .then((accessToken) => {
                handleLogin(accessToken);
                window.location.href = "/works";
            })
            .catch((message) => {
                if (!(message instanceof TypeError)) {
                    notification.error({message: message});
                }
                notification.error({
                    message: 'При выполнении запроса произошла ошибка. Повторите пожалуйста позже'
                });
            })
            .finally(() => setLoading(false))
    };

    return (
        <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
            <Col span={8}>
                <h1>Авторизация</h1>
                <Spin spinning={loading} tip="Обновление...">
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите email',
                                },
                            ]}
                        >
                            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите пароль',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Пароль"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Войти
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Col>
        </Row>
    );
};

export default HomePage;