import {Checkbox, Form} from "antd";
import React from "react";


export default function FormFieldCheckbox({name, label, initialValue}) {
    return (
        <Form.Item
            name={name}
            label={label}
            initialValue={initialValue}
            valuePropName="checked"
        >
            <Checkbox/>
        </Form.Item>
    );
}