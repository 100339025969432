import LayoutPrivatePage from "../../components/LayoutPrivatePage";
import {useParams} from "react-router-dom";
import {Col, Row} from "antd";
import React from "react";
import {CategoryEditForm} from "../../modules/Categories";

const CategoriesDetailPage = () => {
    let { categoryId } = useParams();

    return (
        <LayoutPrivatePage titlePage={`Редактирование категории №${categoryId}`}>
            <Row justify="center">
                <Col span={24} style={{textAlign: "center"}}>
                    <h1>Редактирование категории №{categoryId}</h1>
                </Col>
                <Col span={24} style={{maxWidth: '1000px'}}>
                    <CategoryEditForm categoryId={categoryId}/>
                </Col>
            </Row>
        </LayoutPrivatePage>
    );
};

export default CategoriesDetailPage;