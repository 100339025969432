import TextArea from "antd/es/input/TextArea";
import {Form} from "antd";
import React from "react";


export default function FormFieldTextArea({
      name,
      label,
      message,
      initialValue,
      error = null,
      minRows = 2,
      maxRoes = 6,
      required = true
}) {
    const params = (error !== null) ? {validateStatus: "error", help: error} : {};

    return (
        <Form.Item
            name={name}
            label={label}
            rules={[
                {
                    required: required,
                    message: message,
                    whitespace: true,
                },
            ]}
            initialValue={initialValue}
            {...params}
        >
            <TextArea
                autoSize={{ minRows: minRows, maxRows: maxRoes }}
            />
        </Form.Item>
    );
}