import React, {useEffect, useState} from "react";
import FormFieldCodeEditor from "../../../../ui/components/FormFieldCodeEditor";
import FormFieldSelect from "../../../../ui/components/FormFieldSelect";
import ContentBlockHeader from "../ContentBlockHeader";
import {Button, Col, Row, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {getFieldValidationError} from "../../../../helpers/form.helper";
import { Reorder, useDragControls } from "framer-motion";

export const CONTENT_BLOCK_TYPE_HTML_V1 = 'html_v1';
export const CONTENT_BLOCK_TYPE_IMAGES_AND_SLIDER = 'images_and_slider';
export const CONTENT_BLOCK_TYPE_IMAGE_GALLERY_AND_LIGHTBOX = 'image_gallery_and_lightbox';
export const CONTENT_BLOCK_TYPE_SIMPLE_IMAGE_SWITCHER = 'simple_image_switcher';
export const CONTENT_BLOCK_TYPE_SMART_IMAGE_SWITCHER = 'smart_image_switcher';

export const htmlDefaultStructure = {
    html: "<div class=\"content on\">" +
        "<div class=\"column_3\">" +
        "Что-то" +
        "</div>" +
        "</div>"
};

const imagesAndSliderDefaultStructure = {
    json: {
        "classColumn": null,
        "images": [
            {
                "path": "https://cdn.rb-design.ru/files/.../.../.....jpg"
            },
        ],
    }
}

const simpleImageSwitcherDefaultStructure = {
    json: {
        "largeWidth": "454px",
        "classContainer": null,
        "images": [
            {
                "large": "https://cdn.rb-design.ru/files/.../.../05.gif",
                "selectedThumbnail": "https://cdn.rb-design.ru/files/.../.../05thmbselected.jpg",
                "thumbnail": "https://cdn.rb-design.ru/files/.../.../05thmb.jpg"
            },
        ]
    }
}

const smartImageSwitcherDefaultStructure = {
    json: {
        "classContainer": "something-class",
        "sizes" : {
            "largeParams": {
                "width": 870,
                "height": 429,
                "topMargin": 3,
                "rightMargin": 122,
                "bottomMargin": 24,
                "leftMargin": 122
            }
        },
        "images": [
            {
                "large": "https://cdn.rb-design.ru/files/.../.../01.jpg",
                "selectedThumbnail": "https://cdn.rb-design.ru/files/.../.../01thmbselected.jpg",
                "thumbnail": "https://cdn.rb-design.ru/files/.../.../01thmb.jpg"
            },
        ]
    }
}

const imageGalleryAndLighboxDefaultStructure = {
    json: {
        "sizes": {
            "thumbnail": {
                "width": 250,
                "height": 250,
            },
        },
        "images": [
            {
                "thumbnail": {
                    "path": "https://cdn.rb-design.ru/files/.../.../01-thmb.jpg",
                },
                "large": {
                    "path": "https://cdn.rb-design.ru/files/.../.../01.jpg",
                    "width": 3360,
                    "height": 1350,
                },
            },
            {
                "thumbnail": {
                    "path": "https://cdn.rb-design.ru/files/.../.../02-thmb.jpg",
                },
                "large": {
                    "path": "https://cdn.rb-design.ru/files/.../.../02.jpg",
                    "width": 1590,
                    "height": 1950,
                },
            },
        ]
    }
};


function ListContentBlockItem({item, keyItem, onDeleteContentBlock, validationErrors}) {
    const dragControls = useDragControls();

    const number = keyItem + 1;

    if (item.type === CONTENT_BLOCK_TYPE_HTML_V1) {
        return (
            <Reorder.Item
                key={`content_block_${item.type}_${item.id}`}
                value={item}
                dragListener={false}
                dragControls={dragControls}
            >
                <Row>
                    <Col xs={24} lg={24}>
                        <ContentBlockHeader
                            key={`content_block_header_${item.type}_${item.id}`}
                            type={item.type}
                            number={number}
                            onClick={onDeleteContentBlock(item.id)}
                            dragControls={dragControls}
                        />
                    </Col>
                    <Col xs={24} lg={24}>
                        <FormFieldCodeEditor
                            language="html"
                            name={`content_blocks[${item.id}]`}
                            label={null}
                            message={`Заполните контентный блок №${item.id}`}
                            initialValue={item.html}
                        />
                    </Col>
                </Row>
            </Reorder.Item>
        );
    }


    return (
        <Reorder.Item
            key={`content_block_${item.type}_${item.id}`}
            value={item}
            dragListener={false}
            dragControls={dragControls}
        >
            <Row>
                <Col xs={24} lg={24}>
                    <ContentBlockHeader
                        key={`content_block_header_${item.type}_${item.id}`}
                        type={item.type}
                        number={number}
                        onClick={onDeleteContentBlock(item.id)}
                        dragControls={dragControls}
                    />
                </Col>
                <Col xs={24} lg={24}>
                    <FormFieldCodeEditor
                        name={`content_blocks[${item.id}]`}
                        language="json"
                        label={null}
                        message={`Заполните контентный блок №${item.id}`}
                        initialValue={item.json}
                        error={getFieldValidationError(validationErrors, `contentBlocks.${keyItem}.json`)}
                    />
                </Col>
            </Row>
        </Reorder.Item>
    );
}

function ListContentBlocks({listBlocks, setListBlocks, onDeleteContentBlock, validationErrors}) {

    if (!listBlocks.length) {
        return (<p style={{color: "#f80101"}}>Нет контентных блоков</p>);
    }

    return (
        <Reorder.Group
            axis="y"
            values={listBlocks}
            onReorder={setListBlocks}
            style={{listStyleType: 'none', padding: 0, cursor: 'move'}}
        >
            {listBlocks.map((item, key) => (
                <ListContentBlockItem
                    item={item}
                    keyItem={key}
                    onDeleteContentBlock={onDeleteContentBlock}
                    validationErrors={validationErrors}
                />
            ))}
        </Reorder.Group>
    );
}

export default function ContentBlocks({listContentBlocks, setListContentBlocks, validationErrors}) {
    const [selectedNewBlock, setSelectedNewBlock] = useState(CONTENT_BLOCK_TYPE_HTML_V1);
    const [loading, setLoading] = useState(true);

    function generateNewId () {
        return (Math.random() + 1).toString(36).substring(7);
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const onChangeSelectedNewBlock = (value) => {
        setLoading(true);
        setSelectedNewBlock(value);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    const onAddNewContentBlock = (e) => {
        e.preventDefault();
        setLoading(true);

        if (selectedNewBlock === CONTENT_BLOCK_TYPE_HTML_V1) {
            setListContentBlocks([
                ...listContentBlocks,
                {
                    id: generateNewId(),
                    type: CONTENT_BLOCK_TYPE_HTML_V1,
                    html: htmlDefaultStructure.html,
                    json: null
                }
            ]);
        } else if (selectedNewBlock === CONTENT_BLOCK_TYPE_IMAGES_AND_SLIDER) {
            setListContentBlocks([
                ...listContentBlocks,
                {
                    id: generateNewId(),
                    type: CONTENT_BLOCK_TYPE_IMAGES_AND_SLIDER,
                    html: null,
                    json: JSON.stringify(imagesAndSliderDefaultStructure.json, null, 2)
                }
            ]);
        } else if (selectedNewBlock === CONTENT_BLOCK_TYPE_IMAGE_GALLERY_AND_LIGHTBOX) {
            setListContentBlocks([
                ...listContentBlocks,
                {
                    id: generateNewId(),
                    type: CONTENT_BLOCK_TYPE_IMAGE_GALLERY_AND_LIGHTBOX,
                    html: null,
                    json: JSON.stringify(imageGalleryAndLighboxDefaultStructure.json, null, 2)
                }
            ]);
        } else if (selectedNewBlock === CONTENT_BLOCK_TYPE_SIMPLE_IMAGE_SWITCHER) {
            setListContentBlocks([
                ...listContentBlocks,
                {
                    id: generateNewId(),
                    type: CONTENT_BLOCK_TYPE_SIMPLE_IMAGE_SWITCHER,
                    html: null,
                    json: JSON.stringify(simpleImageSwitcherDefaultStructure.json, null, 2)
                }
            ]);
        } else if (selectedNewBlock === CONTENT_BLOCK_TYPE_SMART_IMAGE_SWITCHER) {
            setListContentBlocks([
                ...listContentBlocks,
                {
                    id: generateNewId(),
                    type: CONTENT_BLOCK_TYPE_SMART_IMAGE_SWITCHER,
                    html: null,
                    json: JSON.stringify(smartImageSwitcherDefaultStructure.json, null, 2)
                }
            ]);
        }

        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    const onDeleteContentBlock = (deletingId) => {
        return (e) => {
            e.preventDefault();
            setLoading(true);
            setListContentBlocks(
                listContentBlocks.filter((contentBlock) => contentBlock.id !== deletingId)
            );
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    }

    return (
        <Row>
            <Col xs={24} lg={24} style={{marginBottom: '15px'}}>
                <span style={{fontSize: '11pt', fontWeight:"bold"}}>
                    Добавить новый контентный блок
                </span>
            </Col>
            <Col xs={24} lg={18}>
                <FormFieldSelect
                    name="newContentBlock"
                    label={null}
                    message="Выберите новый контентный блок"
                    onChange={onChangeSelectedNewBlock}
                    values={[
                        {
                            id: CONTENT_BLOCK_TYPE_HTML_V1,
                            title: 'HTML блок'
                        },
                        {
                            id: CONTENT_BLOCK_TYPE_IMAGES_AND_SLIDER,
                            title: 'IMAGES AND SLIDER блок'
                        },
                        {
                            id: CONTENT_BLOCK_TYPE_IMAGE_GALLERY_AND_LIGHTBOX,
                            title: 'IMAGE GALLERY AND LIGHTBOX блок'
                        },
                        {
                            id: CONTENT_BLOCK_TYPE_SIMPLE_IMAGE_SWITCHER,
                            title: 'SIMPLE IMAGE SWITCHER блок'
                        },
                        {
                            id: CONTENT_BLOCK_TYPE_SMART_IMAGE_SWITCHER,
                            title: 'SMART IMAGE SWITCHER блок'
                        }
                    ]}
                    initialValue={CONTENT_BLOCK_TYPE_HTML_V1}
                    disabled={loading}
                />
            </Col>
            <Col xs={24} lg={6} style={{textAlign: "right"}}>
                <Button
                    type="dashed"
                    onClick={onAddNewContentBlock}
                    style={{ maxWidth: '500px' }}
                    icon={<PlusOutlined />}
                    disabled={loading}
                >
                    Добавить
                </Button>
            </Col>
            <Col xs={24} lg={24}>
                <span style={{fontSize: '11pt', fontWeight:"bold"}}>Список контентных блоков (можно изменять порядок)</span>
            </Col>
            <Col xs={24} lg={24} style={{minHeight: '150px'}}>
                <Spin tip="Обновление..." spinning={loading}>
                    <ListContentBlocks
                        listBlocks={listContentBlocks}
                        setListBlocks={setListContentBlocks}
                        onDeleteContentBlock={onDeleteContentBlock}
                        validationErrors={validationErrors}
                    />
                </Spin>
            </Col>
        </Row>
    );
}
