import React from "react";
import {Form, Input} from "antd";

export default function FormFieldInput({name, label, message, initialValue, error = null, required = true, whitespace = true}) {

    const params = (error !== null) ? {validateStatus: "error", help: error} : {};

    return (
        <Form.Item
            name={name}
            label={label}
            rules={[
                {
                    required: required,
                    message: message,
                    whitespace: whitespace,
                },
            ]}
            initialValue={initialValue}
            {...params}
        >
            <Input/>
        </Form.Item>
    );
}