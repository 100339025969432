import React, {useEffect, useState} from 'react';
import Spinner from "../../../../components/Spinner";
import {categoriesApi} from "../../../../api/categories.api";
import CategoryForm from "../Form";
import {notification, Spin} from "antd";

export default function CategoryCreateForm ({}) {
    const [categoryData, setCategoryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [validationErrors, setValidationErrors] = useState([]);
    const [blockedSubmit, setBlockedSubmit] = useState(false);

    useEffect(() => {
        if (loading) {
            setCategoryData({
                "title": "",
                "titleList": "",
                "slug": "",
                "priority": 10,
                "active": 0,
                "main": 0,
                "favorite": 0,
                "seoPreviewImage": "https://cdn.rb-design.ru/files/posters/---.jpg",
                "seoDescription": ""
            });
            setLoading(false);
        }
    }, [])

    const onSendFormToApi = (categoryFormData) => {
        const newData = {
            ...categoryFormData
        };

        setCategoryData({
            ...categoryData,
            newData
        });

        setLoading(true);
        categoriesApi.createCategory(newData)
            .then((data) => {
                notification.success({message: data.message});
                setValidationErrors([]);
                setCategoryData(data.data);
                setBlockedSubmit(true);
                setLoading(false);
            })
            .catch((data) => {
                if (data.status === 400) {
                    setValidationErrors(data.data.errors);
                } else {
                    notification.error({message: data.toString()});
                }
                setLoading(false);
            })
    };

    if (loading && categoryData === null) {
        return (<Spinner loading={loading}/>);
    }

    return (
        <Spin spinning={loading}>
            <CategoryForm
                categoryData={categoryData}
                loading={loading}
                validationErrors={validationErrors}
                onSendFormToApi={onSendFormToApi}
                isBlockedSubmit={blockedSubmit}
            />
        </Spin>
    );
};