import React from 'react';
import LayoutPrivatePage from "../../components/LayoutPrivatePage";
import {Col, Row} from "antd";
import BrandCreateForm from "../../modules/Brands/components/FormCreate";

const BrandCreatingPage = () => {
    return (
        <LayoutPrivatePage titlePage="Новый бренд">
            <Row justify="center">
                <Col span={24} style={{textAlign: "center", maxWidth: '1000px'}}>
                    <h1>Новый бренд</h1>
                </Col>
                <Col span={24} style={{maxWidth: '1000px'}}>
                    <BrandCreateForm/>
                </Col>
            </Row>
        </LayoutPrivatePage>
    );
};

export default BrandCreatingPage;