import React, {useEffect, useState} from 'react';
import {notification, Spin} from 'antd';
import {worksApi} from "../../../../api/works.api";
import Spinner from "../../../../components/Spinner";
import {brandsApi} from "../../../../api/brands.api";
import {categoriesApi} from "../../../../api/categories.api";
import {CONTENT_BLOCK_TYPE_HTML_V1} from "../ContentBlocks";
import WorkForm from "../Form";
import {generateNewId} from "../../../../helpers/form.helper";

const defaultWorkContentBlock = {
    id: generateNewId(),
    type: CONTENT_BLOCK_TYPE_HTML_V1,
    html: '<div class="content">\n' +
        '    <div class="column-3">\n' +
        '        <h1 class="work">[[TAG_TITLE_CURRENT_WORK]]</h1>\n' +
        '        <p class="info"><span class="date">[[TAG_DATE_CURRENT_WORK]]</span></p>\n' +
        '        <p>...</p>\n' +
        '    </div>\n' +
        '</div>'
};

export default function WorkCreateForm () {
    const [workData, setWorkData] = useState(null);
    const [blockedSubmit, setBlockedSubmit] = useState(false);
    const [listBrands, setListBrands] = useState(null);
    const [listCategories, setListCategories] = useState(null);
    const [loading, setLoading] = useState(true);
    const [listContentBlocks, setListContentBlocks] = useState([
        defaultWorkContentBlock
    ]);
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        brandsApi.getBrands().then((data) => {
            setListBrands(
                data.sort((a, b) => a.title.localeCompare(b.title))
            )
        });
        categoriesApi.getCategories().then((data) => {
            setListCategories(
                data.sort((a, b) => a.title.localeCompare(b.title))
            );
        });
    }, []);

    useEffect(() => {
        if (loading && listBrands !== null && listCategories !== null) {
            setWorkData({
                titleList: '',
                titleDetail: '',
                smallDesc: '',
                slug: '',
                preview: {
                    type: 'image',
                    path: 'https://cdn.rb-design.ru/files/-/-/gallery-poster.jpg',
                    pathPoster: 'https://cdn.rb-design.ru/files/-/-/gallery-poster.jpg',
                    pathVideo: 'https://cdn.rb-design.ru/files/-/-/gallery-video.mp4',
                },
                date: '',
                dateText: null,
                uri: '',
                categories: listCategories.filter((category) => category.slug === "everything"),
                contentBlocks: [
                    defaultWorkContentBlock
                ],
                brand: null,
                hash: null,
                active: 0,
                favorite: 0,
                favoriteInShortList: 0,
                backgroundIsBlack: 0,
                seoPreviewImage: 'https://cdn.rb-design.ru/files/-/-/sm-poster.jpg',
            });
            setLoading(false);
        }
    }, [listBrands, listCategories])

    const onSendFormToApi = (formData) => {
        setLoading(true);

        const formContentBlocks = listContentBlocks.map((contentBlock) => {
            const nameKey = `content_blocks[${contentBlock.id}]`;
            const valueObject = (contentBlock.type === CONTENT_BLOCK_TYPE_HTML_V1)
                ? {"html": formData[nameKey]}
                : {"json": formData[nameKey]};
            return {
                ...contentBlock,
                ...valueObject
            };
        });

        const newFormData = {
            titleList: formData.titleList,
            titleDetail: formData.titleDetail,
            slug: formData.slug,
            url: workData.url,
            brand: formData.brand,
            categories: formData.categories,
            contentBlocks: formContentBlocks,
            date: formData.date,
            dateText: formData.dateText,
            previewType: formData.previewType,
            previewPath: formData.previewPath,
            previewPathPoster: formData.previewPathPoster,
            previewPathVideo: formData.previewPathVideo,
            smallDesc: formData.smallDesc,
            active: formData.active ? 1 : 0,
            favorite: formData.favorite ? 1 : 0,
            favoriteInShortList: formData.favoriteInShortList ? 1 : 0,
            backgroundIsBlack: formData.backgroundIsBlack ? 1 : 0,
            seoPreviewImage: formData.seoPreviewImage,
        };

        console.log('REQUEST TO BACKEND', newFormData);

        worksApi.createWork(newFormData)
            .then((data) => {
                console.log('RESPONSE TO BACKEND', data.data);
                notification.success({message: data.message});
                setBlockedSubmit(true);
                setValidationErrors([]);
                setLoading(false);
            })
            .catch((data) => {
                if (data.status === 400) {
                    setValidationErrors(data.data.errors);
                } else {
                    notification.error({message: data.toString()});
                }
                setLoading(false);
            })
    };

    if ((workData === null || listBrands === null || listCategories === null) && loading) {
        return (<Spinner loading={loading}/>);
    }

    console.log('UPDATE FORM', workData);
    
    return (
        <Spin spinning={loading} tip="Обновление...">
            <WorkForm
                workData={workData}
                listBrands={listBrands}
                listCategories={listCategories}
                loading={loading}
                listContentBlocks={listContentBlocks}
                setListContentBlocks={setListContentBlocks}
                validationErrors={validationErrors}
                onSendFormToApi={onSendFormToApi}
                isBlockedSubmit={blockedSubmit}
            />
        </Spin>
    );
};