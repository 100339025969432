import React from 'react';
import {useParams} from "react-router-dom";
import LayoutPrivatePage from "../../../components/LayoutPrivatePage";
import {Col, Row} from "antd";
import {BlogPostEditForm} from "../../../modules/Blog";

const BlogPostDetailPage = () => {
    let { postId } = useParams();

    return (
        <LayoutPrivatePage titlePage={`Редактирование поста №${postId}`}>
            <Row justify="center">
                <Col span={24} style={{textAlign: "center", maxWidth: '1000px'}}>
                    <h1>Редактирование поста №{postId}</h1>
                </Col>
                <Col span={24} style={{maxWidth: '1000px'}}>
                    <BlogPostEditForm postId={postId}/>
                </Col>
            </Row>
        </LayoutPrivatePage>
    );
};

export default BlogPostDetailPage;