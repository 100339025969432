import React, {useState} from 'react';
import {notification, Spin} from 'antd';
import Spinner from "../../../../../components/Spinner";
import {CONTENT_BLOCK_TYPE_HTML_V1} from "../ContentBlocks";
import PostForm from "../Form";
import {generateNewId} from "../../../../../helpers/form.helper";
import {blogPostApi} from "../../../../../api/blog/posts.api";

const defaultWorkContentBlock = {
    id: generateNewId(),
    type: CONTENT_BLOCK_TYPE_HTML_V1,
    html: '<div class="content">\n' +
        '    <div class="column-3">\n' +
        '        <h1 class="blog">[[TAG_TITLE_CURRENT_BLOG_POST]]</h1>\n' +
        '        <p class="info"><span class="date">[[TAG_DATE_CURRENT_BLOG_POST]]</span></p>\n' +
        '        <p>...</p>\n' +
        '    </div>\n' +
        '</div>'
};

export default function BlogPostCreateForm () {
    const [postData, setPostData] = useState({
        titleList: '',
        titleDetail: '',
        smallDesc: '',
        slug: '',
        preview: {
            type: 'image',
            path: 'https://cdn.rb-design.ru/files/blog/-/gallery-poster.jpg',
            pathPoster: 'https://cdn.rb-design.ru/files/blog/-/gallery-poster.jpg',
            pathVideo: 'https://cdn.rb-design.ru/files/blog/-/gallery-video.mp4',
        },
        date: '',
        uri: '',
        contentBlocks: [
            defaultWorkContentBlock
        ],
        hash: null,
        active: 0,
        seoPreviewImage: 'https://cdn.rb-design.ru/files/blog/-/sm-poster.jpg',
    });
    const [blockedSubmit, setBlockedSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listContentBlocks, setListContentBlocks] = useState([
        defaultWorkContentBlock
    ]);
    const [validationErrors, setValidationErrors] = useState([]);

    const onSendFormToApi = (formData) => {
        setLoading(true);

        const formContentBlocks = listContentBlocks.map((contentBlock) => {
            const nameKey = `content_blocks[${contentBlock.id}]`;
            const valueObject = (contentBlock.type === CONTENT_BLOCK_TYPE_HTML_V1)
                ? {"html": formData[nameKey]}
                : {"json": formData[nameKey]};
            return {
                ...contentBlock,
                ...valueObject
            };
        });

        const newFormData = {
            titleList: formData.titleList,
            titleDetail: formData.titleDetail,
            slug: formData.slug,
            url: postData.url,
            contentBlocks: formContentBlocks,
            date: formData.date,
            previewType: formData.previewType,
            previewPath: formData.previewPath,
            previewPathPoster: formData.previewPathPoster,
            previewPathVideo: formData.previewPathVideo,
            smallDesc: formData.smallDesc,
            active: formData.active ? 1 : 0,
            seoPreviewImage: formData.seoPreviewImage,
        };

        blogPostApi.createPost(newFormData)
            .then((data) => {
                console.log('RESPONSE TO BACKEND', data.data);
                notification.success({message: data.message});
                setBlockedSubmit(true);
                setValidationErrors([]);
                setLoading(false);
            })
            .catch((data) => {
                if (data.status === 400) {
                    setValidationErrors(data.data.errors);
                } else {
                    notification.error({message: data.toString()});
                }
                setLoading(false);
            })
    };

    if ((postData === null) && loading) {
        return (<Spinner loading={loading}/>);
    }
    
    return (
        <Spin spinning={loading} tip="Обновление...">
            <PostForm
                postData={postData}
                loading={loading}
                listContentBlocks={listContentBlocks}
                setListContentBlocks={setListContentBlocks}
                validationErrors={validationErrors}
                onSendFormToApi={onSendFormToApi}
                isBlockedSubmit={blockedSubmit}
            />
        </Spin>
    );
};