const hostName = typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : '';

const apiUrl = (hostName.indexOf('localhost') + 1 || hostName.indexOf('127.0.0.1') + 1)
    ? `http://127.0.0.1/api`
    : 'https://api.rb-design.ru/api';


const frontendUrl = (hostName.indexOf('localhost') + 1 || hostName.indexOf('127.0.0.1') + 1)
    ? `http://127.0.0.1:3000`
    : 'https://develop.rb-design.ru/';

const frontendWorkPreviewUrl = frontendUrl + '/admin-preview-work?hash=';
const frontendBlogPostPreviewUrl = frontendUrl + '/admin-preview-blog-post?hash=';

export const mainConfig = {
    apiUrl : apiUrl,
    frontendUrl : frontendUrl,
    frontendWorkPreviewUrl : frontendWorkPreviewUrl,
    frontendBlogPostPreviewUrl : frontendBlogPostPreviewUrl,
};
