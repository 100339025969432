import LayoutPrivatePage from "../../components/LayoutPrivatePage";
import {WorkList} from "../../modules/Works";

const WorksPage = () => {
    return (
        <LayoutPrivatePage titlePage="Работы">
            <WorkList/>
        </LayoutPrivatePage>
    );
};

export default WorksPage;