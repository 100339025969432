import React, {useEffect, useState} from "react";
import {AuthContext} from "./contexts/auth.context";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ConfigProvider} from "antd";
import HomePage from "./pages/HomePage";
import WorksPage from "./pages/works/WorksPage";
import WorkCreatingPage from "./pages/works/WorkCreatingPage";
import WorkDetailPage from "./pages/works/WorkDetailPage";
import BrandsPage from "./pages/brands/BrandsPage";
import BrandCreatingPage from "./pages/brands/BrandCreatingPage";
import BrandDetailPage from "./pages/brands/BrandDetailPage";
import CategoriesPage from "./pages/categories/CategoriesPage";
import CategoriesCreatingPage from "./pages/categories/CategoriesCreatingPage";
import CategoriesDetailPage from "./pages/categories/CategoriesDetailPage";
import './index.css';
import { StyleProvider } from '@ant-design/cssinjs';
import BlogPostsPage from "./pages/blog/posts/PostsPage";
import PostCreatingPage from "./pages/blog/posts/PostCreatingPage";
import BlogPostDetailPage from "./pages/blog/posts/PostDetailPage";

export default function App() {
    const [userToken, setUserToken] = useState('');
    const [userName, setUserName] = useState('');

    useEffect(() => {
        if (!userToken){
            const storageUserToken = localStorage.getItem('gd-admin-token');
            if (typeof storageUserToken !== "undefined") {
                setUserToken(storageUserToken);
            }
        }
    }, []);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/works",
            element: <WorksPage />,
        },
        {
            path: "/works/create",
            element: <WorkCreatingPage />,
        },
        {
            path: "/works/:workId",
            element: <WorkDetailPage />,
        },
        {
            path: "/brands",
            element: <BrandsPage />,
        },
        {
            path: "/brands/create",
            element: <BrandCreatingPage />,
        },
        {
            path: "/brands/:brandId",
            element: <BrandDetailPage />,
        },
        {
            path: "/categories",
            element: <CategoriesPage />,
        },
        {
            path: "/categories/create",
            element: <CategoriesCreatingPage />,
        },
        {
            path: "/categories/:categoryId",
            element: <CategoriesDetailPage />,
        },
        {
            path: "/blog/posts",
            element: <BlogPostsPage />,
        },
        {
            path: "/blog/posts/create",
            element: <PostCreatingPage />,
        },
        {
            path: "/blog/posts/:postId",
            element: <BlogPostDetailPage />,
        },
    ]);

    const handleLogin = (token) => {
        setUserToken(token);
        localStorage.setItem('gd-admin-token', token.toString());
    };

    const handleLogout = () => {
        setUserToken('');
        localStorage.clear();
    };

    const getUserName = () => {
        return userName;
    };

    const handleSetUserName = (userName) => {
        setUserName(userName);
    };

    return (
        <AuthContext.Provider
            value={{
                handleLogin: handleLogin,
                handleLogout: handleLogout,
                getUserName: getUserName,
                setUserName: handleSetUserName
            }}
        >
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#3366bb',
                    },
                }}
            >
                <StyleProvider hashPriority="high">
                    <RouterProvider router={router} />
                </StyleProvider>
            </ConfigProvider>
        </AuthContext.Provider>
    );
}