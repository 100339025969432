import React from "react";
import FormItemGroup from "../../../../ui/components/FormItemGroup";
import {Button, Col, FloatButton, Form, Popconfirm, Row} from "antd";
import FormFieldInput from "../../../../ui/components/FormFieldInput";
import {getFieldValidationError} from "../../../../helpers/form.helper";
import FormFieldSelect from "../../../../ui/components/FormFieldSelect";
import PreviewFields from "../PreviewFields";
import FormFieldMultipleSelect from "../../../../ui/components/FormFieldMultipleSelect";
import FormFieldTextArea from "../../../../ui/components/FormFieldTextArea";
import ContentBlocks from "../ContentBlocks";
import FormFieldCheckbox from "../../../../ui/components/FormFieldCheckbox";
import {
    ColumnWidthOutlined, DeleteOutlined,
    FileTextOutlined,
    SaveOutlined, TagsOutlined, TranslationOutlined
} from "@ant-design/icons";
import {mainConfig} from "../../../../config";
import FormFieldInputNumber from "../../../../ui/components/FormFieldInputNumber";

export default function WorkForm(
    {
         workData,
         listContentBlocks,
         setListContentBlocks,
         listBrands,
         listCategories,
         validationErrors,
         onSendFormToApi,
        isBlockedSubmit = false,
        isEdit = false,
        onDelete
    }
) {

    const insertTagTitleCurrentWork = e => {
        let textToInsert = "[[TAG_TITLE_CURRENT_WORK]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagDateCurrentWork = e => {
        let textToInsert = "[[TAG_DATE_CURRENT_WORK]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertHtmlSymbolNbsp = e => {
        let textToInsert = "&#160;"
        const isInserted = document.execCommand("insertText", true, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagBrandLinkOfCurrentWork = e => {
        let textToInsert = "[[TAG_BRAND_LINK_CURRENT_WORK]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagWorkPath = e => {
        let textToInsert = "[[TAG_WORK_PATH_12345]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagBrandPath = e => {
        let textToInsert = "[[TAG_BRAND_PATH_12345]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagCategoryPath = e => {
        let textToInsert = "[[TAG_CATEGORY_PATH_12345]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagBlogPostPath = e => {
        let textToInsert = "[[TAG_BLOG_POST_PATH_12345]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    return (
        <Form name="form_item_path" layout="vertical" onFinish={onSendFormToApi}>
            <FormItemGroup prefix={['work']}>
                <Row>
                    <Col xs={24} lg={12}>
                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={isBlockedSubmit}>
                            Сохранить
                        </Button>
                    </Col>
                    <Col xs={24} lg={12} style={{textAlign: "right"}}>
                        {
                            (workData.hash)
                                ? <Button
                                    type="primary"
                                    htmlType="button"
                                    icon={<FileTextOutlined />}
                                    style={{backgroundColor: "#108ee9"}}
                                    onClick={() => window.open(`${mainConfig.frontendWorkPreviewUrl}${workData.hash}`, "_blank")}
                                >
                                    Открыть страницу
                                </Button>
                                : null
                        }
                    </Col>
                    <Col xs={24} lg={24} style={{textAlign: "right", paddingTop: '25px'}}>
                        {
                            (isEdit && onDelete)
                                ? (
                                    <Popconfirm
                                        title="Удаление работы"
                                        description={`Вы уверены что хотите удалить работу из базы?`}
                                        okText="Да, удаляем"
                                        placement="bottomRight"
                                        cancelText="Нет"
                                        onConfirm={onDelete(workData.id)}
                                        okType="danger"
                                        icon={<DeleteOutlined style={{color: 'red'}} />}
                                    >
                                        <Button type="primary" icon={<DeleteOutlined />} danger htmlType="button">
                                            Удалить
                                        </Button>
                                    </Popconfirm>
                                )
                                : null
                        }
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} lg={12}>
                        <FormFieldInput
                            name="titleList"
                            label="Заголовок в галерее"
                            message="Укажите заголовок для галереи"
                            initialValue={workData.titleList}
                            error={getFieldValidationError(validationErrors, "titleList")}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <FormFieldInput
                            name="titleDetail"
                            label="Заголовок на странице"
                            message="Укажите заголовок для страницы"
                            initialValue={workData.titleDetail}
                            error={getFieldValidationError(validationErrors, "titleDetail")}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <FormFieldSelect
                            name="brand"
                            label="Бренд"
                            message="Выберите бренд"
                            values={listBrands}
                            initialValue={(workData.brand) ? workData.brand.id : null}
                            error={getFieldValidationError(validationErrors, "brand")}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <FormFieldInput
                            name="slug"
                            label="Слаг"
                            message="Укажите слаг"
                            initialValue={workData.slug}
                            error={getFieldValidationError(validationErrors, "slug")}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <FormFieldInput
                            name="date"
                            label="Дата"
                            message="Укажите дату"
                            initialValue={workData.date}
                            error={getFieldValidationError(validationErrors, "date")}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <FormFieldInput
                            name="dateText"
                            label="Дата текстом"
                            message="Укажите дату"
                            initialValue={workData.dateText}
                            required={false}
                            error={getFieldValidationError(validationErrors, "dateText")}
                        />
                    </Col>

                    <Col xs={24} lg={12}>
                        <div style={{marginBottom: '15px'}}>
                        <span style={{fontSize: '11pt', fontWeight:"bold"}}>
                            Постеры
                        </span>
                        </div>
                        <PreviewFields workData={workData} validationErrors={validationErrors}/>

                        <FormFieldInput
                            name="seoPreviewImage"
                            label="Постер для соцсетей"
                            message="Укажите постер для соцсетей"
                            initialValue={workData.seoPreviewImage}
                            error={getFieldValidationError(validationErrors, "seoPreviewImage")}
                            required={false}
                        />
                        {workData.seoPreviewImage ? <img src={workData.seoPreviewImage} width="200" height="89" alt="Постер для соцсетей указан неверно" style={{marginBottom: '15px', color: 'red'}}/> : null}
                    </Col>
                    <Col xs={24} lg={12}>
                        <FormFieldMultipleSelect
                            name="categories"
                            label="Категории"
                            message="Выберите категорию"
                            values={listCategories}
                            initialValue={(workData.categories.length) ? workData.categories.map((category) => category.id) : []}
                            error={getFieldValidationError(validationErrors, "categories")}
                        />
                        <FormFieldTextArea
                            name="smallDesc"
                            label="Описание"
                            message="Укажите описание"
                            minRows={7}
                            initialValue={workData.smallDesc}
                            error={getFieldValidationError(validationErrors, "smallDesc")}
                        />
                    </Col>
                    <Col xs={24} lg={24}>
                        {isEdit ? <FormFieldInputNumber
                            name="rating"
                            label="Рейтинг"
                            message="Укажите рейтинг"
                            initialValue={workData.rating}
                            required={true}
                            min={0}
                            max={1000}
                            defaultValue={1}
                            error={getFieldValidationError(validationErrors, "rating")}
                        /> : null}
                    </Col>
                    <Col xs={24} lg={6}>
                        <FormFieldCheckbox
                            name="active"
                            label="Активная"
                            initialValue={workData.active}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <FormFieldCheckbox
                            name="favorite"
                            label="Двойной анонс"
                            initialValue={workData.favorite}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <FormFieldCheckbox
                            name="favoriteInShortList"
                            label="Двойной анонс в кратком портфолио"
                            initialValue={workData.favoriteInShortList}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <FormFieldCheckbox
                            name="backgroundIsBlack"
                            label="Фон первого блока тёмный"
                            initialValue={workData.backgroundIsBlack}
                        />
                    </Col>
                    <Col xs={24} lg={24}>
                        <ContentBlocks
                            listContentBlocks={listContentBlocks}
                            setListContentBlocks={setListContentBlocks}
                            validationErrors={validationErrors}
                        />
                    </Col>
                </Row>
                <Row>
                    <FloatButton.Group
                        trigger="hover"
                        type="primary"
                        tooltip={<div>Вставка спец. тегов</div>}
                        style={{right: 45}}
                        icon={<TagsOutlined />}
                    >
                        <FloatButton
                            tooltip={<div>Вставка тега "Заголовок текущей работы"</div>}
                            description="Cur. title"
                            onClick={insertTagTitleCurrentWork}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Дата текущей работы"</div>}
                            description="Cur. date"
                            onClick={insertTagDateCurrentWork}
                            style={{width:'100', height: '100'}}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на текущий бренд работы"</div>}
                            description="Cur. brand"
                            onClick={insertTagBrandLinkOfCurrentWork}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на работу по ID"</div>}
                            description="Work path"
                            shape="square"
                            onClick={insertTagWorkPath}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на бренд по ID"</div>}
                            description="Brand path"
                            shape="square"
                            onClick={insertTagBrandPath}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на категорию по ID"</div>}
                            description="Cat. path"
                            shape="square"
                            onClick={insertTagCategoryPath}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на пост блога по ID"</div>}
                            description="Post path"
                            shape="square"
                            onClick={insertTagBlogPostPath}
                        />
                    </FloatButton.Group>

                    <FloatButton.Group
                        trigger="hover"
                        type="primary"
                        tooltip={<div>Вставка спец. символов</div>}
                        style={{right: 94}}
                        icon={<TranslationOutlined />}
                    >
                        <FloatButton
                            tooltip={<div>Вставка html символа "Неразрывный пробел"</div>}
                            icon={<ColumnWidthOutlined />}
                            onClick={insertHtmlSymbolNbsp}
                        />
                    </FloatButton.Group>
                </Row>
            </FormItemGroup>
        </Form>
    );
}