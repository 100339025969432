import React, { useContext } from 'react';

//Auth Context
export const AuthContext = React.createContext({
    handleLogin: () => {},
    handleLogout: () => {
        localStorage.clear();
    },
    setUserName: (userName) => {},
    getUserName: () => {}
})

//Use Auth Context
export function useAuthContext() {
    return useContext(AuthContext);
}