import React from "react";
import {Form} from "antd";
import "@uiw/react-textarea-code-editor/dist.css";
import CodeEditor from '@uiw/react-textarea-code-editor';
import './custom.css';

export default function FormFieldCodeEditor({name, label, message, initialValue, error = null, language = "html", required = true}) {

    const params = (error !== null) ? {validateStatus: "error", help: error} : {};

    return (
        <Form.Item
            name={name}
            label={label}
            rules={[
                {
                    required: required,
                    message: message,
                    whitespace: true,
                },
            ]}
            initialValue={initialValue}
            validateStatus="error"
            help={error}
            {...params}
        >
            <CodeEditor
                value={initialValue}
                language={language}
                placeholder={message}
                padding={20}
                data-color-mode="dark"
                style={{
                    fontSize: 12,
                    borderColor: '#d9d9d9',
                    borderWidth: '1px',
                    maxWidth: 'calc(100vw - 25px)',
                    borderStyle: 'solid',
                    borderRadius: '5px',
                    backgroundColor: "#fff",
                    color: '#011429',
                    fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace"
                }}
            />
        </Form.Item>
    );
}