import React, {useState} from "react";
import {Form, Select} from "antd";
import PreviewFieldsPaths from "./preview-fields-paths";
import {getFieldValidationError} from "../../../../../helpers/form.helper";

export default function PreviewFields({workData, validationErrors}) {
    const [type, setType] = useState((workData.preview.type) ? workData.preview.type : "image");

    const onChange = (value) => {
        setType(value);
    }

    const listTypesPreview = [
        {
            key: 'image',
            value: 'image',
            label: 'Image',
        },
        {
            key: 'video',
            value: 'video',
            label: 'Video',
        }
    ];

    return (
        <>
            <Form.Item
                name="previewType"
                label="Тип постера для блога"
                hasFeedback
                rules={[{ required: true, message: 'Выберите тип постера для блога' }]}
                initialValue={type}
                error={getFieldValidationError(validationErrors, "previewType")}
            >
                <Select placeholder="Выберите тип постера для блога" onChange={onChange} options={listTypesPreview}/>
            </Form.Item>
            <PreviewFieldsPaths type={type} dataPreview={workData.preview} validationErrors={validationErrors}/>
        </>
    );
}