import React from 'react';
import {Button, Col, Form, Popconfirm, Row, Spin} from 'antd';
import FormItemGroup from "../../../../ui/components/FormItemGroup";
import FormFieldInput from "../../../../ui/components/FormFieldInput";
import FormFieldCheckbox from "../../../../ui/components/FormFieldCheckbox";
import {getFieldValidationError} from "../../../../helpers/form.helper";
import FormFieldTextArea from "../../../../ui/components/FormFieldTextArea";
import {DeleteOutlined, SaveOutlined} from "@ant-design/icons";

export default function CategoryForm ({
    categoryData,
    loading,
    onSendFormToApi,
    validationErrors,
    isBlockedSubmit,
    isEdit = false,
    onDelete
}) {
    return (
        <Form name="form_item_path" layout="vertical" onFinish={onSendFormToApi} >
            <Spin spinning={loading} tip="Обновление...">
                <FormItemGroup prefix={['category']}>
                    <Row gutter={24}>
                        <Col xs={24} lg={8}>
                            <FormFieldInput
                                name="title"
                                label="Категория"
                                message="Укажите категорию"
                                initialValue={categoryData.title}
                                error={getFieldValidationError(validationErrors, "title")}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <FormFieldInput
                                name="titleList"
                                label="Категория в списке"
                                message="Укажите категорию для списка"
                                initialValue={categoryData.titleList}
                                error={getFieldValidationError(validationErrors, "titleList")}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <FormFieldInput
                                name="slug"
                                label="Слаг"
                                message="Укажите слаг"
                                initialValue={categoryData.slug}
                                error={getFieldValidationError(validationErrors, "slug")}
                            />
                        </Col>
                        <Col xs={24} lg={24}>
                            <FormFieldInput
                                name="seoPreviewImage"
                                label="Постер для соцсетей"
                                message="Укажите постер для соцсетей"
                                initialValue={categoryData.seoPreviewImage}
                                error={getFieldValidationError(validationErrors, "seoPreviewImage")}
                                required={false}
                            />
                        </Col>
                        <Col xs={24} lg={24}>
                            <FormFieldTextArea
                                name="seoDescription"
                                label="Описание"
                                message="Укажите описание"
                                minRows={2}
                                initialValue={categoryData.seoDescription}
                                error={getFieldValidationError(validationErrors, "seoDescription")}
                                required={false}
                            />
                        </Col>
                        <Col xs={24} lg={24}>
                            <FormFieldCheckbox
                                name="active"
                                label="Активный"
                                initialValue={categoryData.active}
                            />
                        </Col>
                    </Row>
                </FormItemGroup>
                <Row>
                    <Col xs={24} lg={12}>
                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={isBlockedSubmit}>
                            Сохранить
                        </Button>
                    </Col>
                    {
                        (isEdit && onDelete)
                            ? (
                                <Col xs={24} lg={12} style={{textAlign: "right"}}>
                                    <Popconfirm
                                        title="Удаление категории"
                                        description={`Вы уверены что хотите удалить категорию из базы?`}
                                        okText="Да, удаляем"
                                        placement="bottomRight"
                                        cancelText="Нет"
                                        onConfirm={onDelete(categoryData.id)}
                                        okType="danger" icon={<DeleteOutlined style={{color: 'red'}} />}
                                    >
                                        <Button type="primary" icon={<DeleteOutlined />} danger htmlType="button">
                                            Удалить
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            )
                            : null
                    }

                </Row>
            </Spin>
        </Form>
    );
};