import axios from "axios";
import {mainConfig} from "../config";
import {notification} from "antd";

const _apiBase = mainConfig.apiUrl;

export const httpRequest = {
    get,
    post,
    put,
    del,
    getDefaultMessageError
};

function handleErrorResponse(error) {

    let errorMsg = getDefaultMessageError();

    if (typeof error.response !== "undefined" && error.response.status === 403) {
        notification.error("Ошибка доступа");
    } else if (typeof error.response !== "undefined" && error.response.status === 400) {
        return Promise.reject(error.response);
    } else if (typeof error.response !== "undefined" && typeof error.response.data.message !== "undefined") {
        errorMsg = error.response.data.message;
    }

    return Promise.reject(errorMsg);
}

async function get(urlRelativePath, config = {}) {

    const newConfigHeader = (typeof config.headers !== "undefined")
        ? config.headers
        : {};

    return axios.get(`${_apiBase}/${urlRelativePath}`, {
        ...config,
        headers: {
            ...authHeader(),
            ...newConfigHeader
        }
    }).catch(handleErrorResponse);
}

async function post(urlRelativePath, data, config = {}) {

    const newConfigHeader = (typeof config.headers !== "undefined") ? config.headers : {};

    return await axios.post(`${_apiBase}/${urlRelativePath}`, data, {
        ...config,
        headers: {
            ...authHeader(),
            ...newConfigHeader
        }
    })
        .catch(handleErrorResponse);
}

async function del(urlRelativePath, config = {}) {

    const newConfigHeader = (typeof config.headers !== "undefined") ? config.headers : {};

    return await axios.delete(`${_apiBase}/${urlRelativePath}`, {
        ...config,
        headers: {
            ...authHeader(),
            ...newConfigHeader
        }
    })
        .catch(handleErrorResponse);
}

async function put(urlRelativePath, data, config = {}) {

    const newConfigHeader = (typeof config.headers !== "undefined") ? config.headers : {};

    return await axios.put(`${_apiBase}/${urlRelativePath}`, data,{
        ...config,
        headers: {
            ...authHeader(),
            ...newConfigHeader
        }
    })
        .catch(handleErrorResponse);
}

function authHeader() {
    const userToken = localStorage.getItem('gd-admin-token');

    if (userToken) {
        return { Authorization: 'Bearer ' + userToken };
    } else {
        return {};
    }
}

function getDefaultMessageError() {
    return 'При выполнении запроса произошла ошибка. Повторите пожалуйста позже';
}
