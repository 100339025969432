import React from "react";
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {useAuthContext} from "../contexts/auth.context";

const menu = [
    {
        key: '/works',
        link: '/works',
        label: `Работы`,
    },
    {
        key: '/brands',
        link: '/brands',
        label: `Бренды`,
    },
    {
        key: '/categories',
        link: '/categories',
        label: `Категории`,
    },
    {
        key: '/blog/posts',
        link: '/blog/posts',
        label: `Блог`,
    },
];

export default function TopMenu() {
    const {handleLogout, getUserName} = useAuthContext();
    const location = useLocation();
    const menuItems = menu.map((item, key) => (
        <Menu.Item id={`top-menu-item-${key}`} key={item.key}>
            <Link to={item.link}>{item.label}</Link>
        </Menu.Item>
    ));

    let selectedMenu = '/';

    if (location.pathname !== '/') {
        const searchedMenuItem  = menu
            .filter((item) => location.pathname !== '/' && item.link !== '/')
            .filter((item) => location.pathname.includes(item.link))
            .map((item) => item.key);

        if (searchedMenuItem.length) {
            selectedMenu = searchedMenuItem[0]
        }
    }

    const onClickLogout = () => {
        handleLogout();
        window.location.href = "/";
    }

    const userName = getUserName();

    return(
        <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[selectedMenu]}
        >
            {menuItems}
            <Menu.Item style={{textAlign: 'right', float: 'right', marginLeft: 'auto'}} danger={true} key="logout">
                <Link onClick={onClickLogout}>{userName ? `Выйти (${userName})` : 'Выйти'} </Link>
            </Menu.Item>
        </Menu>
    );
}
