import LayoutPrivatePage from "../../components/LayoutPrivatePage";
import {CategoriesList} from "../../modules/Categories";

const CategoriesPage = () => {
    return (
        <LayoutPrivatePage titlePage="Категории">
            <CategoriesList/>
        </LayoutPrivatePage>
    );
};

export default CategoriesPage;