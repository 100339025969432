import {Form, Select} from "antd";
import React from "react";

export default function FormFieldSelect({name, label, message, error = null, disabled = false, onChange = null, initialValue, values}) {
    const params = (error !== null) ? {validateStatus: "error", help: error} : {};

    const options = values.map((item) => {
        return {
            value: item.id,
            label: item.title,
        }
    });

    const select = (onChange !== null)
        ? <Select placeholder={message} options={options} onChange={onChange} disabled={disabled}/>
        : <Select placeholder={message} options={options} disabled={disabled}/>;

    return (
        <Form.Item
            name={name}
            label={label}
            hasFeedback
            rules={[{ required: true, message: message }]}
            initialValue={initialValue}
            {...params}
        >
            {select}
        </Form.Item>
    );
}