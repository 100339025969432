import {httpRequest} from "./http-request";

export const categoriesApi = {
    getCategories,
    getCategory,
    updateCategory,
    createCategory,
    deleteCategory
};

async function getCategories() {

    return await httpRequest.get('admin/v1/portfolio/categories')
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getCategory(id) {

    return await httpRequest.get(`admin/v1/portfolio/categories/${id}`)
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function updateCategory(id, data) {

    return await httpRequest.put(`admin/v1/portfolio/categories/${id}`, data)
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function createCategory( data) {

    return await httpRequest.post(`admin/v1/portfolio/categories`, data)
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function deleteCategory(id) {

    return await httpRequest.del(`admin/v1/portfolio/categories/${id}`)
        .then((response) => {
            if (
                (response.status === 200 || response.status === 204) &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}
