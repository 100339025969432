import {Button, Col, Input, Row, Space, Table} from "antd";
import {useEffect, useRef, useState} from "react";
import Spinner from "../../../../components/Spinner";
import {CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {categoriesApi} from "../../../../api/categories.api";
import Highlighter from "react-highlight-words";

export default function ListCategories () {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const [countCategoriesOnPage, setCountCategoriesOnPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Найти
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Очистить
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        закрыть
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Категория',
            dataIndex: 'title',
            key: 'title',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Категория в списке',
            dataIndex: 'titleList',
            key: 'titleList',
            ...getColumnSearchProps('titleList'),
        },
        {
            title: 'Слаг',
            dataIndex: 'slug',
            key: 'slug',
            ...getColumnSearchProps('slug'),
        },
        {
            title: 'Место в списке',
            dataIndex: 'priority',
            key: 'priority',
        },
        {
            title: 'Активная',
            dataIndex: 'active',
            key: 'active',
            render: (active, key) => (
                <span key={`field_active_${key}`}>{
                    (active) ? <CheckCircleOutlined /> : <CloseCircleOutlined style={{color: '#f80101'}} />
                }
            </span>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => <a key={`field_action_${record.id}`} href={`/categories/${record.id}`}>Ред.</a>,
        },
    ];

    useEffect(() => {
        categoriesApi.getCategories()
            .then((data) => {
                setCategories(data);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (<Spinner loading={loading}/>);
    }

    const showTotal = (total) => `Всего ${total} категорий`;
    const onShowSizeChange = (current, size) => {
        setCountCategoriesOnPage(size);
    }

    return (
        <>
            <Row gutter={12} justify="space-around" align="middle">
                <Col xs={18} lg={18}>
                    <h1>Категории</h1>
                </Col>
                <Col xs={6} lg={6} style={{textAlign: "right"}}>
                    <Button type="primary" onClick={() => window.location.href = "/categories/create"}>Новая категория</Button>
                </Col>
                <Col xs={24} lg={24}>
                    <Table columns={columns} dataSource={categories} size="small"
                           pagination={{
                               pageSize: countCategoriesOnPage,
                               total: categories.length,
                               showTotal: showTotal,
                               showSizeChanger: true,
                               onShowSizeChange: onShowSizeChange
                           }}
                    />
                </Col>
            </Row>
        </>
    );
};