import React from "react";
import {Button, Col, Popconfirm, Row} from "antd";
import {DeleteOutlined, DragOutlined} from "@ant-design/icons";

export default function ContentBlockHeader({dragControls, number, type, onClick}) {

    return (
        <Row style={{marginBottom: "15px"}} className="content-block-header"
             onPointerDown={(event) => dragControls.start(event)}
        >
            <Col xs={10} lg={20}>
                <DragOutlined
                    twoToneColor="#eb2f96"
                    style={{width:'25px', height:'25px', fontSize: '15px', color: '#3366bb'}}
                />
                <span>Блок №{number} - "{type}" </span>
            </Col>
            <Col xs={6} lg={4} style={{textAlign: "right"}} className="button-delete">
                <Popconfirm
                    placement="leftBottom"
                    title="Вы уверены что хотите удалить?"
                    description={null}
                    onConfirm={onClick}
                    okText="Удалить"
                    cancelText="Отмена"
                >
                    <Button danger size="small" icon={<DeleteOutlined />}>
                        удалить
                    </Button>
                </Popconfirm>
            </Col>
        </Row>
    );
}
