import {httpRequest} from "./http-request";
import axios from "axios";
import {mainConfig} from "../config";

export const authApi = {
    login,
    getUser
};

async function login(email, password) {

    return await axios.post(`${mainConfig.apiUrl}/auth/login`, {
        "email": email,
        "password": password
    }, {headers: {'Content-Type': 'application/json'}})
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined" &&
                typeof response.data.access_token !== "undefined"
            ) {
                return Promise.resolve(response.data.access_token);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        })
        .catch((response) => {
            if (
                (response.response.status === 401 || response.response.status === 403) &&
                typeof response.response.data.error !== "undefined"
            ) {
                return Promise.reject(response.response.data.error);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getUser() {

    return await httpRequest.get('auth/me')
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data.name !== "undefined"
            ) {
                return Promise.resolve(response.data.name);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}
