import React, {useEffect, useState} from 'react';
import {notification, Spin} from 'antd';
import {worksApi} from "../../../../api/works.api";
import Spinner from "../../../../components/Spinner";
import {brandsApi} from "../../../../api/brands.api";
import {categoriesApi} from "../../../../api/categories.api";
import {CONTENT_BLOCK_TYPE_HTML_V1} from "../ContentBlocks";
import WorkForm from "../Form";

export default function WorkEditForm ({workId}) {
    const [workData, setWorkData] = useState(null);
    const [listBrands, setListBrands] = useState(null);
    const [listCategories, setListCategories] = useState(null);
    const [loading, setLoading] = useState(true);
    const [listContentBlocks, setListContentBlocks] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);

    function generateNewId () {
        return (Math.random() + 1).toString(36).substring(7);
    }

    useEffect(() => {
        worksApi.getWork(workId).then((data) => {
            setWorkData(data);
            setListContentBlocks(
                data.contentBlocks.map((item) => {
                    return {...item, id: generateNewId()}
                })
            )
        });
        brandsApi.getBrands().then((data) => {
            setListBrands(
                data.sort((a, b) => a.title.localeCompare(b.title))
            );
        });
        categoriesApi.getCategories().then((data) => {
                setListCategories(
                    data.sort((a, b) => a.title.localeCompare(b.title))
                );
        });
    }, []);

    useEffect(() => {
        if (loading && workData !== null && listBrands !== null && listCategories !== null) {
            setLoading(false);
        }
    }, [workData, listBrands, listCategories])

    const onSendFormToApi = (formData) => {
        setLoading(true);

        const formContentBlocks = listContentBlocks.map((contentBlock) => {
            const nameKey = `content_blocks[${contentBlock.id}]`;
            const valueObject = (contentBlock.type === CONTENT_BLOCK_TYPE_HTML_V1)
                ? {"html": formData[nameKey]}
                : {"json": formData[nameKey]};
            return {
                ...contentBlock,
                ...valueObject
            };
        });

        const newFormData = {
            id: workId,
            titleList: formData.titleList,
            titleDetail: formData.titleDetail,
            slug: formData.slug,
            url: workData.url,
            rating: formData.rating,
            brand: formData.brand,
            categories: formData.categories,
            contentBlocks: formContentBlocks,
            date: formData.date,
            dateText: formData.dateText,
            previewType: formData.previewType,
            previewPath: formData.previewPath,
            previewPathPoster: formData.previewPathPoster,
            previewPathVideo: formData.previewPathVideo,
            smallDesc: formData.smallDesc,
            active: formData.active ? 1 : 0,
            favorite: formData.favorite ? 1 : 0,
            favoriteInShortList: formData.favoriteInShortList ? 1 : 0,
            backgroundIsBlack: formData.backgroundIsBlack ? 1 : 0,
            seoPreviewImage: formData.seoPreviewImage,
        };

        console.log('REQUEST TO BACKEND', newFormData);

        worksApi.updateWork(workId, newFormData)
            .then((data) => {

                console.log('RESPONSE TO BACKEND', data.data);

                notification.success({message: data.message});
                setValidationErrors([]);
                setWorkData(data.data);
                setLoading(false);
            })
            .catch((data) => {
                if (data.status === 400) {
                    setValidationErrors(data.data.errors);
                } else {
                    notification.error({message: data.toString()});
                }
                setLoading(false);
            })
    };

    if ((workData === null || listBrands === null || listCategories === null) && loading) {
        return (<Spinner loading={loading}/>);
    }

    console.log('UPDATE FORM', workData);

    const onDeleteWork = (id) => {
        return (e) => {
            e.preventDefault();
            setLoading(true);
            worksApi.deleteWork(id)
                .then((data) => {
                    notification.success({message: data.message.toString()});
                    setTimeout(() => {
                        window.location.href = "/works";
                    }, 3000);
                })
                .catch((data) => {
                    notification.error({message: data.toString()});
                    setLoading(false);
                    setTimeout(() => {
                        window.location.href = "/works";
                    }, 3000);
                });
        }
    }
    
    return (
        <Spin spinning={loading} tip="Обновление...">
            <WorkForm
                workData={workData}
                listBrands={listBrands}
                listCategories={listCategories}
                loading={loading}
                listContentBlocks={listContentBlocks}
                setListContentBlocks={setListContentBlocks}
                validationErrors={validationErrors}
                onSendFormToApi={onSendFormToApi}
                isEdit={true}
                onDelete={onDeleteWork}
            />
        </Spin>
    );
};