import React from "react";
import {Form, InputNumber} from "antd";

export default function FormFieldInputNumber({
     name,
     label,
     message,
     initialValue,
     min,
     max,
     defaultValue,
     error = null,
     required = true,
     whitespace = true
}) {

    const params = (error !== null) ? {validateStatus: "error", help: error} : {};

    return (
        <Form.Item
            name={name}
            label={label}
            rules={[
                {
                    required: required,
                    message: message,
                    type: 'number',
                    min: min,
                    max: max,
                    whitespace: whitespace,
                },
            ]}
            initialValue={initialValue}
            {...params}
        >
            <InputNumber precision={2} style={{width: '100%'}}/>
        </Form.Item>
    );
}