import {httpRequest} from "./http-request";

export const brandsApi = {
    getBrands,
    getBrand,
    updateBrand,
    createBrand,
    deleteBrand
};

async function getBrands() {

    return await httpRequest.get('admin/v1/portfolio/brands')
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getBrand(id) {

    return await httpRequest.get(`admin/v1/portfolio/brands/${id}`)
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function updateBrand(id, newData) {

    return await httpRequest.put(`admin/v1/portfolio/brands/${id}`, newData)
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            if (
                response.status === 400 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.reject(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function createBrand(newData) {

    return await httpRequest.post(`admin/v1/portfolio/brands`, newData)
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            if (
                response.status === 400 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.reject(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function deleteBrand(id) {

    return await httpRequest.del(`admin/v1/portfolio/brands/${id}`)
        .then((response) => {
            if (
                (response.status === 200 || response.status === 204) &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            if (
                response.status === 400 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.reject(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}
