import LayoutPrivatePage from "../../components/LayoutPrivatePage";
import {useParams} from "react-router-dom";
import {Col, Row} from "antd";
import React from "react";
import BrandEditForm from "../../modules/Brands/components/FormEdit";

const BrandDetailPage = () => {
    let { brandId } = useParams();

    return (
        <LayoutPrivatePage titlePage={`Редактирование бренда №${brandId}`}>
            <Row justify="center">
                <Col span={24} style={{textAlign: "center"}}>
                    <h1>Редактирование бренда №{brandId}</h1>
                </Col>
                <Col span={24}>
                    <BrandEditForm brandId={brandId}/>
                </Col>
            </Row>
        </LayoutPrivatePage>
    );
};

export default BrandDetailPage;