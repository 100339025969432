import React from 'react';
import LayoutPrivatePage from "../../../components/LayoutPrivatePage";
import {Col, Row} from "antd";
import {BlogPostCreateForm} from "../../../modules/Blog";

const PostCreatingPage = () => {
    return (
        <LayoutPrivatePage titlePage="Новый пост">
            <Row justify="center">
                <Col span={24} style={{textAlign: "center", maxWidth: '1000px'}}>
                    <h1>Новый пост</h1>
                </Col>
                <Col span={24} style={{maxWidth: '1000px'}}>
                    <BlogPostCreateForm/>
                </Col>
            </Row>
        </LayoutPrivatePage>
    );
};

export default PostCreatingPage;