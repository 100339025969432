import React from "react";
import {Form, Select} from "antd";

export default function FormFieldMultipleSelect({name, label, message, initialValue, values, error = null}) {
    const params = (error !== null) ? {validateStatus: "error", help: error} : {};
    const options = values.map((item) => {
        return {
            key: item.id,
            value: item.id,
            label: item.title,
        }
    });

    return (
        <Form.Item
            name={name}
            label={label}
            hasFeedback
            rules={[{ required: true, message: message, type: 'array' }]}
            initialValue={initialValue}
            {...params}
        >
            <Select mode="multiple" placeholder={message} options={options}/>
        </Form.Item>
    );
}