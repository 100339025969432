import FormFieldInput from "../../../../../ui/components/FormFieldInput";
import React from "react";
import {getFieldValidationError} from "../../../../../helpers/form.helper";

export default function PreviewFieldsPaths({type, dataPreview, validationErrors}) {

    if (type ==="image") {
        return (
            <FormFieldInput
                name="previewPath"
                label="Постер для блога"
                message="Укажите постер для блога"
                initialValue={dataPreview.path}
                error={getFieldValidationError(validationErrors, "previewPath")}
            />
        );
    } else {
        return (
            <>
                <FormFieldInput
                    name="previewPathPoster"
                    label="Видеопостер для блога"
                    message="Укажите видеопостер для блога"
                    initialValue={dataPreview.pathPoster}
                    error={getFieldValidationError(validationErrors, "previewPathPoster")}
                />
                <FormFieldInput
                    name="previewPathVideo"
                    label="Видео для блога"
                    message="Укажите видео для блога"
                    initialValue={dataPreview.pathVideo}
                    error={getFieldValidationError(validationErrors, "previewPathVideo")}
                />
            </>
        );
    }
}