import {httpRequest} from "./http-request";

export const worksRecommendationApi = {
    reindexRecommendation,
    getInfo
};

async function getInfo() {

    return await httpRequest.get('admin/v1/portfolio/works/recommendations/info')
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function reindexRecommendation() {

    return await httpRequest.post(`admin/v1/portfolio/works/recommendations/reindex`, {})
        .then((response) => {
            if (
                (response.status === 200 || response.status === 409) &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}
