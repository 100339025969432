import LayoutPrivatePage from "../../components/LayoutPrivatePage";
import {BrandList} from "../../modules/Brands";

const BrandsPage = () => {
    return (
        <LayoutPrivatePage titlePage="Бренды">
            <BrandList/>
        </LayoutPrivatePage>
    );
};

export default BrandsPage;