import LayoutPrivatePage from "../../../components/LayoutPrivatePage";
import {ListPosts} from "../../../modules/Blog";

const BlogPostsPage = () => {
    return (
        <LayoutPrivatePage titlePage="Блог">
            <ListPosts/>
        </LayoutPrivatePage>
    );
};

export default BlogPostsPage;