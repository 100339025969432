import React, {useEffect, useState} from 'react';
import Spinner from "../../../../components/Spinner";
import {categoriesApi} from "../../../../api/categories.api";
import CategoryForm from "../Form";
import {notification} from "antd";

export default function CategoryEditForm ({categoryId}) {
    const [categoryData, setCategoryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        categoriesApi.getCategory(categoryId).then((data) => setCategoryData(data));
    }, []);

    useEffect(() => {
        if (loading && categoryData !== null) {
            setLoading(false);
        }
    }, [categoryData])

    const onSendFormToApi = (categoryData) => {
        const newData = {
            ...categoryData
        };

        setCategoryData(newData);

        setLoading(true);
        categoriesApi.updateCategory(categoryId, newData)
            .then((data) => {
                notification.success({message: data.message.toString()});
                setValidationErrors([]);
                setCategoryData(data.data);
                setLoading(false);
            })
            .catch((data) => {
                if (data.status === 400) {
                    setValidationErrors(data.data.errors);
                } else {
                    notification.error({message: data.toString()});
                }
                setLoading(false);
            })
    };

    if (loading || categoryData === null) {
        return (<Spinner loading={loading}/>);
    }

    const onDeleteCategory = (id) => {
        return (e) => {
            e.preventDefault();
            setLoading(true);
            categoriesApi.deleteCategory(id)
                .then((data) => {
                    notification.success({message: data.message.toString()});
                    setTimeout(() => {
                        window.location.href = "/categories";
                    }, 3000);
                })
                .catch((data) => {
                    notification.error({message: data.toString()});
                    setLoading(false);
                    setTimeout(() => {
                        window.location.href = "/categories";
                    }, 3000);
                });
        }
    }

    return (
        <CategoryForm
            categoryData={categoryData}
            loading={loading}
            validationErrors={validationErrors}
            onSendFormToApi={onSendFormToApi}
            isEdit={true}
            onDelete={onDeleteCategory}
        />
    );
};