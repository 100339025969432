import {Button, Col, Input, notification, Popconfirm, Row, Space, Table} from "antd";
import {useEffect, useRef, useState} from "react";
import Spinner from "../../../../components/Spinner";
import {worksApi} from "../../../../api/works.api";
import {CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import {worksRecommendationApi} from "../../../../api/work-recommendations.api";

export default function ListWorks () {
    const [works, setWorks] = useState([]);
    const [dateLastUpdatedRecommendation, setDateLastUpdatedRecommendation] = useState('');
    const [loading, setLoading] = useState(true);

    const [countWorkOnPage, setCountWorkOnPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Найти
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Очистить
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        закрыть
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Заголовок в галерее',
            dataIndex: 'titleList',
            key: 'titleList',
            ...getColumnSearchProps('titleList'),
        },
        {
            title: 'Url',
            dataIndex: 'uri',
            key: 'uri',
            ...getColumnSearchProps('uri'),
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Текст вместо даты',
            dataIndex: 'dateText',
            key: 'dateText',
        },
        {
            title: 'Бренд',
            dataIndex: 'brand',
            key: 'brandTitle',
            render: (brand) => (brand) ? <span key={`brand_item_${brand.id}`}>{brand.title}</span> : '',
        },
        {
            title: 'Рейтинг',
            dataIndex: 'rating',
            key: 'rating',
            render: (rating, key) => (
                <span key={`field_rating_${key}`}>{rating.toFixed(2)}
            </span>
            ),
        },
        {
            title: 'Акт.',
            dataIndex: 'active',
            key: 'active',
            render: (active, key) => (
                <span key={`field_active_${key}`}>{
                    (active) ? <CheckCircleOutlined /> : <CloseCircleOutlined style={{color: '#f80101'}} />
                }
            </span>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => <a key={`action_item_${record.id}`} href={`/works/${record.id}`}>Ред.</a>,
        },
    ];

    useEffect(() => {
        worksApi.getWorks()
            .then((data) => {
                setWorks(data);
                setLoading(false);
            });

        worksRecommendationApi.getInfo()
            .then((data) => {
                setDateLastUpdatedRecommendation(data.date);
            });
    }, []);

    if (loading) {
        return (<Spinner loading={loading}/>);
    }

    const showTotal = (total) => `Всего ${total} работ`;
    const onShowSizeChange = (current, size) => {
        setCountWorkOnPage(size);
    }

    const handleSendStartUpdatingWorkRecommendations = () => {
        worksRecommendationApi.reindexRecommendation()
            .then((data) => {
                notification.success({message: data.message.toString()});
            })
            .catch((message) => {
                notification.error({message: message.toString()});
            });
    }

    return (
        <>
            <Row gutter={12} justify="space-around" align="middle">
                <Col xs={18} lg={18}>
                    <h1>Работы</h1>
                </Col>
                <Col xs={6} lg={6} style={{textAlign: "right"}}>
                    <Button type="primary" onClick={() => window.location.href = "/works/create"}>Новая работа</Button>
                </Col>
                <Col xs={24} lg={24}>
                    <Table columns={columns}
                           dataSource={works}
                           size="small"
                           pagination={{
                               pageSize: countWorkOnPage,
                               total: works.length,
                               showTotal: showTotal,
                               showSizeChanger: true,
                               onShowSizeChange: onShowSizeChange
                            }}
                    />
                </Col>
                <Col xs={24} lg={24}>
                    <h1>Доп. функции</h1>
                </Col>
                <Col xs={24} lg={24}>
                    <Popconfirm
                        title="Обновить рекомендации"
                        description="Вы уверены что хотите запустить обновление рекомендованных?"
                        okText="Запустить"
                        cancelText="Нет"
                        placement="topLeft"
                        onConfirm={handleSendStartUpdatingWorkRecommendations}
                    >
                        <Button type="primary">Обновить рекомендации</Button>
                    </Popconfirm>
                    {
                        dateLastUpdatedRecommendation
                            ? <>
                                <br/><span style={{fontSize: '9pt', paddingTop: '10px'}}>время посл. обновления: {dateLastUpdatedRecommendation}</span>
                            </>
                            : null
                    }

                </Col>
            </Row>
        </>
    );
};