import React from 'react';
import LayoutPrivatePage from "../../components/LayoutPrivatePage";
import {Col, Row} from "antd";
import {CategoryCreateForm} from "../../modules/Categories";

const CategoriesCreatingPage = () => {
    return (
        <LayoutPrivatePage titlePage="Новая категория">
            <Row justify="center">
                <Col span={24} style={{textAlign: "center", maxWidth: '1000px'}}>
                    <h1>Новая категория</h1>
                </Col>
                <Col span={24} style={{maxWidth: '1000px'}}>
                    <CategoryCreateForm/>
                </Col>
            </Row>
        </LayoutPrivatePage>
    );
};

export default CategoriesCreatingPage;