import React from 'react';
import {useParams} from "react-router-dom";
import LayoutPrivatePage from "../../components/LayoutPrivatePage";
import {Col, Row} from "antd";
import WorkEditForm from "../../modules/Works/components/FormEdit";

const WorkDetailPage = () => {
    let { workId } = useParams();

    return (
        <LayoutPrivatePage titlePage={`Редактирование работы №${workId}`}>
            <Row justify="center">
                <Col span={24} style={{textAlign: "center", maxWidth: '1000px'}}>
                    <h1>Редактирование работы №{workId}</h1>
                </Col>
                <Col span={24} style={{maxWidth: '1000px'}}>
                    <WorkEditForm workId={workId}/>
                </Col>
            </Row>
        </LayoutPrivatePage>
    );
};

export default WorkDetailPage;