import {Layout, notification, theme} from 'antd';
import TopMenu from "./TopMenu";
import {useEffect, useState} from "react";
import Spinner from "./Spinner";
import {authApi} from "../api/auth.api";
import {useAuthContext} from "../contexts/auth.context";

const { Header, Content, Footer } = Layout;

const LayoutPrivatePage = ({children, titlePage = 'CMS'}) => {
    const [loading, setLoading] = useState(true);
    const { setUserName } = useAuthContext();

    useEffect(() => {
        document.title = titlePage;

        authApi.getUser()
            .then((userName) => {
                setUserName(userName);
            })
            .catch((message) => {
                if (!(message instanceof TypeError)) {
                    notification.error({message: message});
                }
                notification.error({
                    message: 'Нет доступа'
                });
                window.location.href = "/";
            })
            .finally((res) => setLoading(false));
    }, []);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    if (loading) {
        return (<></>);
    }

    return (
        <Layout className="layout">
            <Header title="test">
                <TopMenu/>
            </Header>
            <Content className="layout-content"
                     style={{
                }}
            >
                <div
                    className="site-layout-content"
                    style={{
                        background: colorBgContainer,
                        minHeight: 'calc(100vh - 185px)'
                    }}
                >
                    {(loading) ? <Spinner loading={loading}/> : children}
                </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    background: '#f5f5f5'
                }}
            />
        </Layout>
    );
};
export default LayoutPrivatePage;