import {httpRequest} from "./http-request";

export const worksApi = {
    getWorks,
    getWork,
    updateWork,
    createWork,
    deleteWork
};

async function getWorks() {

    return await httpRequest.get('admin/v1/portfolio/works')
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getWork(id) {

    return await httpRequest.get(`admin/v1/portfolio/works/${id}`)
        .then((response) => {
            if (
                response.status === 200 &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function createWork(data) {

    return await httpRequest.post(`admin/v1/portfolio/works`, data)
        .then((response) => {
            if (
                (response.status === 200 || response.status === 400) &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function updateWork(id, data) {

    return await httpRequest.put(`admin/v1/portfolio/works/${id}`, data)
        .then((response) => {
            if (
                (response.status === 200 || response.status === 400) &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function deleteWork(id) {

    return await httpRequest.del(`admin/v1/portfolio/works/${id}`)
        .then((response) => {
            if (
                ((response.status === 200 || response.status === 204) || response.status === 400) &&
                typeof response.data !== "undefined"
            ) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}
