import React from "react";
import FormItemGroup from "../../../../../ui/components/FormItemGroup";
import {Button, Col, FloatButton, Form, Popconfirm, Row} from "antd";
import FormFieldInput from "../../../../../ui/components/FormFieldInput";
import {getFieldValidationError} from "../../../../../helpers/form.helper";
import PreviewFields from "../PreviewFields";
import FormFieldTextArea from "../../../../../ui/components/FormFieldTextArea";
import ContentBlocks from "../ContentBlocks";
import FormFieldCheckbox from "../../../../../ui/components/FormFieldCheckbox";
import {
    ColumnWidthOutlined, DeleteOutlined,
    FileTextOutlined,
    SaveOutlined, TagsOutlined, TranslationOutlined
} from "@ant-design/icons";
import {mainConfig} from "../../../../../config";

export default function PostForm(
    {
         postData,
         listContentBlocks,
         setListContentBlocks,
         validationErrors,
         onSendFormToApi,
        isBlockedSubmit = false,
        isEdit = false,
        onDelete
    }
) {
    const insertHtmlSymbolNbsp = e => {
        let textToInsert = "&#160;"
        const isInserted = document.execCommand("insertText", true, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagWorkPath = e => {
        let textToInsert = "[[TAG_WORK_PATH_12345]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagBrandPath = e => {
        let textToInsert = "[[TAG_BRAND_PATH_12345]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagCategoryPath = e => {
        let textToInsert = "[[TAG_CATEGORY_PATH_12345]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    const insertTagBlogPostPath = e => {
        let textToInsert = "[[TAG_BLOG_POST_PATH_12345]]";
        const isInserted = document.execCommand("insertText", false, textToInsert);
        if (!isInserted) {
            navigator.clipboard.writeText(textToInsert);
        }
        e.preventDefault();
    }

    return (
        <Form name="form_item_path" layout="vertical" onFinish={onSendFormToApi}>
            <FormItemGroup prefix={['post']}>
                <Row>
                    <Col xs={24} lg={12}>
                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={isBlockedSubmit}>
                            Сохранить
                        </Button>
                    </Col>
                    <Col xs={24} lg={12} style={{textAlign: "right"}}>
                        {
                            (postData.hash)
                                ? <Button
                                    type="primary"
                                    htmlType="button"
                                    icon={<FileTextOutlined />}
                                    style={{backgroundColor: "#108ee9"}}
                                    onClick={() => window.open(`${mainConfig.frontendBlogPostPreviewUrl}${postData.hash}`, "_blank")}
                                >
                                    Открыть страницу
                                </Button>
                                : null
                        }
                    </Col>
                    <Col xs={24} lg={24} style={{textAlign: "right", paddingTop: '25px'}}>
                        {
                            (isEdit && onDelete)
                                ? (
                                    <Popconfirm
                                        title="Удаление поста"
                                        description={`Вы уверены что хотите удалить пост блога из базы?`}
                                        okText="Да, удаляем"
                                        placement="bottomRight"
                                        cancelText="Нет"
                                        onConfirm={onDelete(postData.id)}
                                        okType="danger"
                                        icon={<DeleteOutlined style={{color: 'red'}} />}
                                    >
                                        <Button type="primary" icon={<DeleteOutlined />} danger htmlType="button">
                                            Удалить
                                        </Button>
                                    </Popconfirm>
                                )
                                : null
                        }
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} lg={12}>
                        <FormFieldInput
                            name="titleList"
                            label="Заголовок в блоге"
                            message="Укажите заголовок для блога"
                            initialValue={postData.titleList}
                            error={getFieldValidationError(validationErrors, "titleList")}
                        />
                        <FormFieldInput
                            name="titleDetail"
                            label="Заголовок в посте"
                            message="Укажите заголовок для поста"
                            initialValue={postData.titleDetail}
                            error={getFieldValidationError(validationErrors, "titleDetail")}
                        />
                        <Row gutter={24}>
                            <Col xs={24} lg={12}>
                                <FormFieldInput
                                    name="slug"
                                    label="Слаг"
                                    message="Укажите slug"
                                    initialValue={postData.slug}
                                    error={getFieldValidationError(validationErrors, "slug")}
                                />
                            </Col>
                            <Col xs={24} lg={12}>
                                <FormFieldInput
                                    name="date"
                                    label="Дата"
                                    message="Укажите дату"
                                    initialValue={postData.date}
                                    error={getFieldValidationError(validationErrors, "date")}
                                />
                            </Col>
                        </Row>
                        <FormFieldTextArea
                            name="smallDesc"
                            label="Описание"
                            message="Укажите описание"
                            minRows={7}
                            initialValue={postData.smallDesc}
                            error={getFieldValidationError(validationErrors, "smallDesc")}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <PreviewFields workData={postData} validationErrors={validationErrors}/>

                        <FormFieldInput
                            name="seoPreviewImage"
                            label="Постер для соцсетей"
                            message="Укажите постер для соцсетей"
                            initialValue={postData.seoPreviewImage}
                            error={getFieldValidationError(validationErrors, "seoPreviewImage")}
                            required={false}
                        />
                        {postData.seoPreviewImage ? <img src={postData.seoPreviewImage} width="200" height="89" alt="Постер для соцсетей указан неверно" style={{marginBottom: '15px', color: 'red'}}/> : null}
                    </Col>
                    <Col xs={24} lg={24}>
                        <FormFieldCheckbox
                            name="active"
                            label="Активный"
                            initialValue={postData.active}
                        />
                    </Col>
                    <Col xs={24} lg={24}>
                        <ContentBlocks
                            listContentBlocks={listContentBlocks}
                            setListContentBlocks={setListContentBlocks}
                            validationErrors={validationErrors}
                        />
                    </Col>
                </Row>
                <Row>
                    <FloatButton.Group
                        trigger="hover"
                        type="primary"
                        tooltip={<div>Вставка спец. тегов</div>}
                        style={{right: 45}}
                        icon={<TagsOutlined />}
                    >
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на работу по ID"</div>}
                            description="Work path"
                            shape="square"
                            onClick={insertTagWorkPath}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на бренд по ID"</div>}
                            description="Brand path"
                            shape="square"
                            onClick={insertTagBrandPath}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на категорию по ID"</div>}
                            description="Cat. path"
                            shape="square"
                            onClick={insertTagCategoryPath}
                        />
                        <FloatButton
                            tooltip={<div>Вставка тега "Ссылка на пост блога по ID"</div>}
                            description="Post path"
                            shape="square"
                            onClick={insertTagBlogPostPath}
                        />
                    </FloatButton.Group>

                    <FloatButton.Group
                        trigger="hover"
                        type="primary"
                        tooltip={<div>Вставка спец. символов</div>}
                        style={{right: 94}}
                        icon={<TranslationOutlined />}
                    >
                        <FloatButton
                            tooltip={<div>Вставка html символа "Неразрывный пробел"</div>}
                            icon={<ColumnWidthOutlined />}
                            onClick={insertHtmlSymbolNbsp}
                        />
                    </FloatButton.Group>
                </Row>
            </FormItemGroup>
        </Form>
    );
}